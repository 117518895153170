fieldset {
  width: 180px;
  margin: 0;
  margin-bottom: 0.5em;
  border: 1px solid black;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

legend {
  font-weight: bold;
  padding-left: 0;
}

label {
  display: block;
}

input[type="checkbox" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

a {
  text-decoration: underline;
  color: blue;
}
